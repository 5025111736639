import { ReactComponent as TwitterIcon } from "../lib/icons/Twitter.svg";

export const social4 = [
  {
    socialIcon: (
      <TwitterIcon className="h-5 w-5 lg:h-10 lg:w-10 fill-[#0ddae1] hover:fill-accent"></TwitterIcon>
    ),
    socialLink: "https://twitter.com/blacktigerq8",
    socialTitle: "Twitter",
  },
];
