import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Define the initial state
const initialState = {
  feedback: null,
  error: null,
  isLoading: false,
};

// Create an async thunk action to send feedback data to the server
export const sendFeedback = createAsyncThunk(
  "feedback/sendFeedback",
  async (feedbackData) => {
    try {
      const response = await axios.post("/api/feedback", feedbackData);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }
);

// Create the feedback slice
const feedbackSlice = createSlice({
  name: "feedback",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sendFeedback.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(sendFeedback.fulfilled, (state, action) => {
        state.isLoading = false;
        state.feedback = action.payload;
      })
      .addCase(sendFeedback.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

// Export the feedback reducer
export default feedbackSlice.reducer;
