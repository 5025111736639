export const education = [
  {
    eduDate: "2013 - 2014",
    eduTitle: "The American English and Culture Program",
    eduIns: "Arizona State University",
    eduDesc:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    eduDate: "2015 - 2019",
    eduTitle: "Bachelor of Science in Civil Engineering",
    eduIns: "Northern Arizona University",
    eduDesc:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    eduDate: "2008 - 2012",
    eduTitle: "Certification in PMP Preparation",
    eduIns:
      "Kuwait society of Engineers - Engineering Development Trainging Cetner",
    eduDesc:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
];
