import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { sendFeedback } from "../../redux/feedbackSlice";
import axios from "axios";

const FeedbackForm = () => {
  const [comment, setComment] = useState("");
  const [name, setName] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [rating, setRating] = useState(0);

  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const feedbackData = {
      comment,
      name,
      jobTitle,
      rating,
    };

    try {
      // Dispatch the sendFeedback action
      await dispatch(sendFeedback(feedbackData));

      // Save the feedback data in localStorage
      localStorage.setItem("feedback", JSON.stringify(feedbackData));

      // Send the feedback data to the backend server
      await axios.post("/api/feedback", feedbackData);

      // Reset the form fields
      setComment("");
      setName("");
      setJobTitle("");
      setRating(0);

      // Show success message to the user
      alert("Feedback submitted successfully");
    } catch (error) {
      console.error("Error submitting feedback:", error);
      // Show error message to the user
      alert("Failed to submit feedback. Please try again.");
    }
  };

  return (
    <>
      <div>
        <h2>Feedback Form</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="comment">Comment:</label>
            <textarea
              id="comment"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              required
            ></textarea>
          </div>
          <div>
            <label htmlFor="name">Name:</label>
            <input
              id="name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="jobTitle">Job Title:</label>
            <input
              id="jobTitle"
              type="text"
              value={jobTitle}
              onChange={(e) => setJobTitle(e.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="rating">Rating:</label>
            <input
              id="rating"
              type="number"
              min="1"
              max="5"
              value={rating}
              onChange={(e) => setRating(parseInt(e.target.value))}
              required
            />
          </div>
          <button type="submit">Submit Feedback</button>
        </form>
      </div>
    </>
  );
};

export default FeedbackForm;
