import ExpImg1 from "../assets/images/aaImages/expImg1.png";
import ExpImg2 from "../assets/images/aaImages/expImg1.png";
import ExpImg3 from "../assets/images/aaImages/expImg3.png";
import ExpImg4 from "../assets/images/aaImages/expImg4.png";

export const workExp = [
  {
    expCount: "1",
    expDate: "2020 - 2021",
    expImg: ExpImg1,
    expTitle: "Kuwait Municipality",
    expSubTitle: "Engineering Licensing Department",
    expDesc:
      "Reading architechtural and structural engineer drawings and compare them to the completed projects on the site to ensure all the starture is identical to the provided drawings.",
  },
  {
    expCount: "2",
    expDate: "2021 - 2023",
    expImg: ExpImg2,
    expTitle: "Kuwait Municipality",
    expSubTitle: "Engineering Audit and Inspection Department",
    expDesc:
      "Recieving the architectural drawings and ensure all the requirements are met in accordance with Kuwait Municipality regulations.",
  },
  {
    expCount: "3",
    expDate: "2019 - 2023",
    expImg: ExpImg3,
    expTitle: "Full Stack developer",
    expSubTitle: "Freelancing",
    expDesc:
      "Building a complete responsive apps to meet the client's satisfaction.",
  },
  {
    expCount: "4",
    expDate: "2021 - 2023",
    expImg: ExpImg4,
    expTitle: "3D Designers",
    expSubTitle: "Freelancing",
    expDesc:
      "Working as a freelancer to convert the architectural drawings into a realetic 3D model that could be applied with the appropriate scale in real life.",
  },
];
