import React, { useRef, useState } from "react";
import emailjs from "emailjs-com";
import * as BsIcons from "react-icons/bs";
import { SectionTitleLight2 } from "../SectionTitles";

const ContactForm = () => {
  const formRef = useRef();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_jle5cb2", // Service ID service_7ue15na
        "template_wers87p", // Template ID template_saq3qcd
        formRef.current,
        "user_ankTwhYsBKlHR484R4hL4" // Public Key X60ZU_FD3tSyYORAv
      )
      .then(
        (result) => {
          console.log(result.text);
          setIsSubmitted(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const handleReset = () => {
    setIsSubmitted(false);
  };

  return (
    <div className="contactFormWrap relative w-full">
      {!isSubmitted ? (
        <form
          ref={formRef}
          onSubmit={handleSubmit}
          className="grid gap-[1.875rem] md:grid-cols-2 grid-cols-1"
        >
          <div className="fieldBox w-full col-span-2">
            <label className="text-[1rem] text-white font-Poppins font-medium px-[10px] md:px-[1.125rem] mb-[10px] block">
              Write a Message
            </label>
            <textarea
              name="message"
              required
              className="border-2 border-solid border-[#a9a9a9] focus:border-[rgba(255,255,255,.5)] focus:outline-0 bg-[transparent] rounded-[10px] md:rounded-[.9375rem] lg:rounded-[1.25rem] w-full h-[8rem] md:h-[13.125rem] p-[1.125rem] text-white"
            ></textarea>
          </div>
          <div className="fieldBox w-full">
            <label className="text-[1rem] text-white font-Poppins font-medium px-[10px] md:px-[1.125rem] mb-[10px] block">
              Your Name
            </label>
            <input
              name="user_name"
              type="text"
              required
              className="border-2 border-solid border-[#a9a9a9] focus:border-[rgba(255,255,255,.5)] focus:outline-0 bg-[transparent] rounded-[10px] md:rounded-[.9375rem] lg:rounded-[1.25rem] w-full h-[2.5rem] md:h-[3.875rem] p-[10px] md:p-[1.125rem] text-white"
            ></input>
          </div>
          <div className="fieldBox w-full">
            <label className="text-[1rem] text-white font-Poppins font-medium px-[10px] md:px-[1.125rem] mb-[10px] block">
              Your Email
            </label>
            <input
              name="user_email"
              type="email"
              required
              className="border-2 border-solid border-[#a9a9a9] focus:border-[rgba(255,255,255,.5)] focus:outline-0 bg-[transparent] rounded-[10px] md:rounded-[.9375rem] lg:rounded-[1.25rem] w-full h-[2.5rem] md:h-[3.875rem] p-[10px] md:p-[1.125rem] text-white"
            ></input>
          </div>
          <div className="fieldbtn mt-0 md:mt-[1.875rem] w-full col-span-2">
            <button
              className="bg-white text-accent text-[1rem] font-Poppins font-bold uppercase rounded-[5px] md:rounded-[10px] md:px-6 lg:px-10 xl:px-11 px-7 md:py-[1.125rem] py-[14px] hover:bg-accent2 hover:text-white text-center inline-block"
              type="submit"
            >
              Contact Me
            </button>
          </div>
        </form>
      ) : (
        <div className="reset-container contact-text">
          <div className="thnx">
            <SectionTitleLight2
              title="Thank"
              titleInner="You"
              desc="Your message has been sent."
            ></SectionTitleLight2>

            <button
              onClick={handleReset}
              value="Submit another message"
              className="bg-white text-accent text-[1rem] font-Poppins font-bold uppercase rounded-[5px] md:rounded-[10px] md:px-6 lg:px-10 xl:px-11 px-7 md:py-[1.125rem] py-[14px] hover:bg-accent2 hover:text-white text-center inline-block"
            >
              Submit another message
            </button>
          </div>
        </div>
      )}
      {/* Contact Form Wrap */}
    </div>
  );
};

export default ContactForm;
